import moment from "moment";
import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Badge, Container } from "react-bootstrap";
import {
  IoChatboxOutline,
  IoCloseOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import CustomModal from "../../components/utils/CustomModal";
import { deleteOrder, getOrders } from "../../services/order";
import { updateNotification } from "../../store/reducers/notificationSlice";

const Orders = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const inputRef = createRef();

  const orderNotification = useSelector((state) => state.notification.order);

  const [orders, setOrders] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    isShow: false,
    id: false,
  });

  const orderColumns = [
    {
      name: "Покупатель",
      cell: (row) => (
        <Link to={`/user/${row?.user?.id}`}>{row?.user?.nickname}</Link>
      ),
    },
    {
      name: "Продавец",
      cell: (row) =>
        row?.author?.id ? (
          <Link to={`/user/${row?.author?.id}`}>{row?.author?.nickname}</Link>
        ) : (
          "-"
        ),
    },
    {
      name: "Сумма",
      selector: "total",
    },
    {
      name: "Дата",
      selector: "createdAt",
      showDesc: true,
      showDescIcon: false,
      cell: (row) => (
        <>
          <span className="me-1">
            {moment(row.createdAt).format("DD.MM.YYYY")}
          </span>
          <span className="fw-7">{moment(row.createdAt).format("kk:mm")}</span>
        </>
      ),
    },
    {
      align: "center",
      name: "Лот",
      cell: (row) => (row?.product?.desc ? row.product.desc : "-"),
    },
    {
      width: "130px",
      name: "Статус",
      align: "center",
      cell: (row) =>
        !row.status || row.status == "process" ? (
          <Badge bg="secondary">В процессе</Badge>
        ) : row.status == "ok" ? (
          <Badge bg="success">Завершен</Badge>
        ) : row.status == "cancel" ? (
          <Badge bg="danger">Отменен</Badge>
        ) : (
          row.status === "refund" && <Badge bg="danger">Возврат</Badge>
        ),
    },
    // {
    //   width: "80px",
    //   selector: "action",
    //   align: "right",
    //   cell: (row) => {
    //     return (
    //       <>
    //         <div className="d-flex align-items-center">
    //           <Link to={"/order/" + row.id} className="me-2">
    //             <IoCreateOutline size={22} />
    //           </Link>
    //         </div>
    //       </>
    //     );
    //   },
    // },
  ];

  const header = useMemo(() => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <h5 className="fw-7">
              {selected.length > 0 ? `Выбрано ${selected.length}` : "Сделки"}
            </h5>
          </div>
          <Select
            classNameContainer="ms-5"
            label="Сортировка"
            data={[
              { title: "По дате добавления: новые", value: "" },
              { title: "По дате добавления: старые", value: "createold" },
              { title: "Стоимость: больше", value: "pricemore" },
              { title: "Стоимость: меньше", value: "priceless" },
            ]}
            value={searchParams.get("sort") ?? ""}
            onClick={(e) => {
              searchParams.set("sort", e.value);
              setSearchParams(searchParams);
              onSearch();
            }}
          />
          <div className="search-box ms-3 me-5">
            <Input
              ref={inputRef}
              placeholder="Найти"
              className="w-100"
              onChange={(e) => {
                searchParams.set("text", e);
                setSearchParams(searchParams);
              }}
              defaultValue={searchParams.get("text")}
              onKeyDown={(e) => e === "Enter" && onSearch()}
            />

            <Button
              className="btn-light ms-3"
              onClick={() => {
                searchParams.delete("text");
                setSearchParams(searchParams);
                onSearch();
                if (inputRef.current) {
                  inputRef.current.value = "";
                }
              }}
            >
              <IoCloseOutline size={22} />
            </Button>
          </div>
          <div className="d-flex align-items-center">
            <Button
              disabled={selected.length === 0}
              className="btn-light"
              onClick={() => setModalDelete({ show: true, id: false })}
            >
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
      </>
    );
  }, [selected, searchParams, modalDelete]);

  const getData = useCallback(async () => {
    // dispatch(updateNotification({ order: -1 }));
    getOrders(searchParams)
      .then((res) => {
        setOrders((prev) => ({
          ...prev,
          loading: false,
          ...res,
        }));
      })
      .finally(() => setOrders((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page"), orderNotification]);

  const clickDelete = (id) => {
    deleteOrder(id).then(() => getData());
    setModalDelete({ isShow: false, id: false });
  };

  if (orders.loading) {
    return <Loader full />;
  }

  return (
    <Container fluid={true}>
      <Meta title="Сделки" />
      <section className="box">
        {/* <Row className="mb-4">
          <Col md={4}>
            <Card body>
              <h5 className="mb-2 fw-6 h6">Кол-во заказов</h5>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h2 className="m-0">{customPrice(statistic.order, false)}</h2>
                  <div className="mt-2">
                    <img src="/images/stat-plus.svg" />{" "}
                    <small className="fw-6">
                      +11.3% <span className="light-gray">за день</span>
                    </small>
                  </div>
                </div>
                <div>
                  <img src="/images/stat-1.svg" height={35} />
                </div>
              </div>
            </Card>
          </Col>
          <Col md={4}>
            <Card body>
              <h5 className="mb-2 fw-6 h6">Выручка</h5>
              <Select
                title="Выберите период"
                className="fs-09"
                data={[
                  {
                    title: "Месяц",
                    value: "month",
                  },
                  {
                    title: "День",
                    value: "day",
                  },
                  {
                    title: "Неделя",
                    value: "weekend",
                  },
                  {
                    title: "Задать период",
                    value: "other",
                  },
                ]}
              />
              <div className="mt-2 d-flex align-items-end">
                <h3 className="m-0 me-2 fw-7 h6">
                  {customPrice(statistic.total)}
                </h3>
                <span className="fs-09 text-muted">за месяц</span>
              </div>
            </Card>
          </Col>
          <Col md={4}>
            <Card body>
              <h5 className="mb-2 fw-6 h6">Средний чек</h5>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h2 className="m-0">{customPrice(statistic.average)}</h2>
                  <div className="mt-2">
                    <img src="/images/stat-plus.svg" />{" "}
                    <small className="fw-6">
                      +11.3% <span className="light-gray">за день</span>
                    </small>
                  </div>
                </div>
                <div>
                  <img src="/images/stat-2.svg" height={55} />
                </div>
              </div>
            </Card>
          </Col>
        </Row> */}
        <DataTable
          columns={orderColumns}
          onChange={(items) => setSelected(items)}
          data={orders.items}
          header={header}
          selectable
          // linkPagination="/orders/"
          // statusBackground="order"
          pagination={orders.pagination}
        />
      </section>
      <CustomModal
        title={`Удаление ${modalDelete.id ? "#" + modalDelete.id : ""}`}
        isShow={modalDelete.isShow}
        setShow={(e) => setModalDelete({ isShow: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={(e) =>
                setModalDelete({ isShow: !modalDelete.isShow, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => modalDelete.id && clickDelete(modalDelete.id)}
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить заказ?
      </CustomModal>
    </Container>
  );
};

export default Orders;
