import React, { useCallback, useLayoutEffect, useState } from "react";
import { Card, Col, Container, Dropdown, Row } from "react-bootstrap";
import { IoEllipsisVertical } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useSearchParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { getImageURL } from "../../helpers/image";
import { deletePartner, getPartners } from "../../services/partner";

const Partners = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [partners, setPartners] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const getData = useCallback(async () => {
    getPartners(searchParams)
      .then(
        (res) =>
          res &&
          setPartners((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setPartners((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onDelete = useCallback((id) => {
    deletePartner(id)
      .then(() => {
        getData();
        NotificationManager.success("Партнеры успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);

  const onDeleteSelected = useCallback(() => {
    deletePartner(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные партнеры успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, [selected]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  if (partners.loading) {
    return <Loader full />;
  }

  return (
    <Container fluid={true}>
      <Meta title="Партнеры" />
      <section className="box">
        <div className="d-flex align-items-center justify-content-between mb-2 w-100">
          <h5 className="fw-7">Партнеры</h5>
          <Link to="/partners/create" className="btn-primary-outline">
            Добавить партнер
          </Link>
        </div>
        {partners?.items?.length > 0 ? (
          <div className="g-2 g-sm-3">
            <Row className=" g-2 g-sm-3 g-xl-4 mb-4">
              {partners.items.map((e) => (
                <Col md={6} xl={4}>
                  <Link to={"/partner/" + e?.id}>
                    <figure class="banner-mini">
                      <img
                        src={getImageURL({
                          path: e.media,
                          size: "full",
                          type: "partner",
                        })}
                        alt={e?.title}
                      />
                      <figcaption className="d-flex justify-content-end align-items-center">
                        <div>
                          <Dropdown className="d-flex align-items-center">
                            <Dropdown.Toggle
                              as={React.forwardRef(
                                ({ children, onClick }, ref) => (
                                  <Link
                                    ref={ref}
                                    className="py-0"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      onClick(e);
                                    }}
                                  >
                                    <IoEllipsisVertical size={20} />
                                  </Link>
                                )
                              )}
                            />
                            <Dropdown.Menu align="end">
                              <Dropdown.Item as={Link} to={"/partner/" + e.id}>
                                Редактировать
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  setModalDelete({
                                    show: !modalDelete.show,
                                    id: e.id,
                                  })
                                }
                              >
                                Удалить
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </figcaption>
                    </figure>
                  </Link>
                </Col>
              ))}
            </Row>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center mb-4">
            <div className="my-5">
              <h3 className="text-center">Нет партнеров</h3>
              <div className="d-flex justify-content-center">
                <Link className="mt-3 btn btn-primary" to="/partners/create">
                  Добавить партнера
                </Link>
              </div>
            </div>
          </div>
        )}
        <CustomModal
          title={
            selected?.length > 0
              ? `Удаление ${selected.length} элементов`
              : "Удаление элемента"
          }
          show={modalDelete.show}
          setShow={(e) => setModalDelete({ show: e, id: false })}
          footer={
            <>
              <Button
                className="me-3"
                onClick={() =>
                  setModalDelete({ show: !modalDelete.show, id: false })
                }
              >
                Отмена
              </Button>
              <Button
                className="btn-danger"
                onClick={() =>
                  selected.length > 0
                    ? onDeleteSelected()
                    : modalDelete.id && onDelete(modalDelete.id)
                }
              >
                Удалить
              </Button>
            </>
          }
        >
          Вы точно хотите удалить партнер(-ов)?
        </CustomModal>
      </section>
    </Container>
  );
};

export default Partners;
