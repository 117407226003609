import moment from "moment";
import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Badge, Col, Row, Container } from "react-bootstrap";
import {
  IoCloseOutline,
  IoCreateOutline,
  IoSearchOutline,
  IoBan,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useParams, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import CustomModal from "../../components/utils/CustomModal";
import { getImageURL } from "../../helpers/image";
// import { getImageURL } from "../../helpers/image";
import { customPrice } from "../../helpers/product";
import { getCategories } from "../../services/category";
import { deleteProduct, getProducts, statusProductAll } from "../../services/product";

const Products = () => {
  const { service = false, type = false } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const inputRef = createRef();

  const [products, setProducts] = useState({
    loading: true,
    items: [],
  });
  // const [categories, setCategories] = useState({
  //   loading: true,
  //   items: [],
  // });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const productColumns = [
    {
      name: "Фото",
      cell: (row) => (
        <a
          href={process.env.REACT_APP_SITE_URL + "/offer/" + row.uid}
          target="_blank"
          className="d-flex align-items-center"
        >
          <div className="position-relative me-2">
            <img
              src={getImageURL({
                path: row?.cover ? row.cover : false,
                type: "product/cover",
                size: "full",
              })}
              width={30}
              height={30}
              className="mini-img"
            />
          </div>
        </a>
      ),
    },
    {
      name: "№",
      selector: "id",
      cell: (row) => (
        <Link to={"/product/" + row.id} className="fs-09">
          {row.id}
        </Link>
      ),
    },
    {
      name: "Категория",
      selector: "category",
      cell: (row) => (
        <Link className="fs-09" to={`/product/${row?.id}`}>
          {row?.category?.title ?? null}
        </Link>
      ),
    },
    {
      name: "Статус",
      selector: "status",
      width: 100,
      cell: (row) => (
        <>
          <Badge
            className="mb-1"
            bg={
              row.status === 0
                ? "secondary"
                : row.status === 1
                  ? "success"
                  : row.status === 2
                    ? "warning"
                    : row.status === -1 && "danger"
            }
          >
            {row.status === 0
              ? "Архив"
              : row.status === 1
                ? "Активен"
                : row.status === 2
                  ? "Модерация"
                  : row.status === -1 && "Отклонен"}
          </Badge>
          {row.categoryId === 1 && (
            <Badge bg={row.transactionStatus ? "success" : "secondary"}>
              {row.transactionStatus ? "Оплачен" : "Не оплачен"}
            </Badge>
          )}
        </>
      ),
    },
    {
      name: "Название",
      selector: "title",
      cell: (row) => (
        <Link className="fs-09" to={`/product/${row?.id}`}>
          {row?.title ?? null}
        </Link>
      ),
    },
    {
      name: "Продавец",
      cell: (row) => (
        <Link className="fs-09" to={`/user/${row?.user?.id}`}>
          {row?.user?.firstName ?? row?.user?.email}
        </Link>
      ),
    },
    {
      name: "Жалобы",
      align: "center",
      cell: (row) => <Link to={`/product/${row?.id}`}>{row?.report ?? 0}</Link>,
    },
    {
      name: "Дата",
      selector: "createdAt",
      cell: (row) => (
        <Link to={"/product/" + row.id} className="fs-09">
          <span className="d-inline-block dateTime">{moment(row.createdAt).format("DD.MM.YYYY kk:mm")}</span>
          {row?.end && <span className="d-inline-block dateTime">{moment(row?.end).format("DD.MM.YYYY kk:mm")}</span>}
        </Link>
      ),
    },
  ];

  const getData = useCallback(async () => {
    getProducts(searchParams)
      .then(
        (res) =>
          res &&
          setProducts((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setProducts((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onDelete = useCallback((id) => {
    deleteProduct(id)
      .then(() => {
        getData();
        NotificationManager.success("Товар успешно удален");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);
  const onStatusAll = useCallback(() => {
    statusProductAll()
      .then(() => {
        getData();
        NotificationManager.success("Товары успешно опубликованы");
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteProduct(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные товары успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, [selected]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams, service, type]);

  // useLayoutEffect(() => {
  //   getCategories({ size: 200 })
  //     .then((res) => {
  //       if (res?.items?.length > 0) {
  //         let array = res.items.map((e) => ({ title: e.title, value: e.id }));
  //         setCategories((prev) => ({
  //           ...prev,
  //           loading: false,
  //           items: array,
  //         }));
  //       }
  //     })
  //     .finally(() => setCategories((prev) => ({ ...prev, loading: false })));
  // }, []);

  useLayoutEffect(() => {
    searchParams.set('service', service);
    searchParams.set('type', type);
    getData();
  }, [searchParams.get("page"), service, type]);

  const header = useMemo(() => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div>
            <h5 className="fw-7">
              {selected.length > 0
                ? `Выбрано ${selected.length}`
                : "Объявления"}
            </h5>
          </div>
          <Select
            classNameContainer="ms-3"
            label="Жалобы"
            data={[
              { title: "Все", value: "" },
              { title: "Большее сверху", value: "desc" },
              { title: "Меньшее сверху", value: "asc" },
            ]}
            value={searchParams.get("report") ?? ""}
            onClick={(e) => {
              searchParams.set("report", e.value);
              setSearchParams(searchParams);
              onSearch();
            }}
          />
          <div className="search-box mx-3">
            <Input
              ref={inputRef}
              placeholder="Найти"
              className="w-100"
              onChange={(e) => {
                searchParams.set("text", e);
                setSearchParams(searchParams);
              }}
              defaultValue={searchParams.get("text")}
              onKeyDown={(e) => e === "Enter" && onSearch()}
            />

            <Button
              className="btn-light ms-3"
              onClick={() => {
                searchParams.delete("text");
                setSearchParams(searchParams);
                onSearch();
                if (inputRef.current) {
                  inputRef.current.value = "";
                }
              }}
            >
              <IoCloseOutline size={22} />
            </Button>
          </div>
          <div>
            <Button
              className="btn-light"
              onClick={onStatusAll}
            >
              Опубликовать все
            </Button>
          </div>

        </div>
        {/* <div className="mb-4">
          <Row>
            <Col md={6}>
              <Select
                classNameContainer="w-100"
                label="Статус"
                data={[
                  { title: "Все", value: "" },
                  { title: "Заблокированные", value: "blocked" },
                  { title: "Подтвержденные", value: "verified" },
                ]}
                value={searchParams?.get("status") ?? ""}
                onClick={(e) => {
                  searchParams.set("status", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={6}>
              <Select
                classNameContainer="w-100"
                label="Сортировка"
                data={[
                  { title: "По дате регистрации: новые", value: "datenew" },
                  { title: "По дате регистрации: старые", value: "dateold" },
                  { title: "Комиссия: больше", value: "commissionmore" },
                  { title: "Комиссия: меньше", value: "commissionless" },
                  { title: "Объявления: больше", value: "adsmore" },
                  { title: "Объявления: меньше", value: "adsless" },
                ]}
                value={searchParams.get("sort") ?? "datenew"}
                onClick={(e) => {
                  searchParams.set("sort", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
          </Row>
        </div> */}
      </>
    );
  }, [selected, searchParams, onStatusAll]);

  if (products?.loading) {
    return <Loader full />;
  }

  return (
    <Container fluid={true}>
      <Meta title="Объявления" />
      <section className="box">
        <DataTable
          columns={productColumns}
          onChange={(items) => setSelected(items)}
          data={products.items}
          header={header}
          selectable
          pagination={products.pagination}
        />
        <CustomModal
          title={
            selected.length > 0
              ? `Удаление ${selected.length} элементов`
              : "Удаление элемента"
          }
          show={modalDelete.show}
          setShow={(e) => setModalDelete({ show: e, id: false })}
          footer={
            <>
              <Button
                className="me-3"
                onClick={() =>
                  setModalDelete({ show: !modalDelete.show, id: false })
                }
              >
                Отмена
              </Button>
              <Button
                className="btn-danger"
                onClick={() =>
                  selected.length > 0
                    ? onDeleteSelected()
                    : modalDelete.id && onDelete(modalDelete.id)
                }
              >
                Удалить
              </Button>
            </>
          }
        >
          Вы точно хотите удалить объявление?
        </CustomModal>
      </section>
    </Container>
  );
};

export default Products;
