import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ImageCropper from "../../components/Cropper";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import Input from "../../components/UI/Input";
import Textarea from "../../components/UI/Textarea";
import { createPartner } from "../../services/partner";

const PartnerCreate = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });
  const [editImagePartner, setEditImagePartner] = useState({
    show: false,
    data: [],
  });

  useEffect(() => {
    searchParams.get("type") &&
      reset({ type: Number(searchParams.get("type")) });
  }, []);

  const onSubmit = useCallback((data) => {
    if (!data?.file) {
      return NotificationManager.error("Добавьте изображение");
    }
    if (!data?.title) {
      return NotificationManager.error("Введите ссылку");
    }
    createPartner(data)
      .then(() => {
        NotificationManager.success("Партнер успешно создан");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  return (
    <Container fluid={true}>
      <Meta title="Создать партнера" />
      <section className="box">
        <div>
          <Link
            to="/partners"
            className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
          >
            <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
          </Link>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3>Создать партнера</h3>
          <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
            Сохранить
          </Button>
        </div>

        <Row>
          <Col md={12}>
            <div className="mb-3">
              <Input
                defaultValue={0}
                label="Порядок"
                name="priority"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3">
              <Input
                label="Ссылка"
                name="title"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={12}>
            <DragDropFile
              file={data.media}
              onChange={(e) =>
                setEditImagePartner((info) => ({
                  show: !info.show,
                  data: e,
                }))
              }
            />
            <ImageCropper
              file={editImagePartner.data[0]}
              show={editImagePartner.show}
              aspect={1.92 / 1}
              setShow={(e) =>
                setEditImagePartner((info) => ({ ...info, show: e }))
              }
              onComplete={(e) => {
                e && setValue("file", e.file);
                e && setValue("media", e.blob);
              }}
            />
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default PartnerCreate;
