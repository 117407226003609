import React from 'react'

const IconTickets = ({className}) => {
  return (
    <svg className={className} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 3H3C1.9 3 1 3.9 1 5V19C1 20.1 1.9 21 3 21H21C22.1 21 23 20.1 23 19V5C23 3.9 22.1 3 21 3ZM20 19H13C12.45 19 12 18.55 12 18V14C12 13.45 12.45 13 13 13H20C20.55 13 21 13.45 21 14V18C21 18.55 20.55 19 20 19Z" fill="currentColor"/>
    </svg>
  )
}

export default IconTickets