import { useSelector } from "react-redux";
import Message from "./Message";
import { IoSend } from "react-icons/io5";
import { memo, useCallback, useState } from "react";
import Input from "../UI/Input";
import { customPrice } from "../../helpers/product";

const Chat = memo(
  ({
    data,
    className,
    emptyText = "Нет сообщений",
    onSubmit,
    onChange,
    autoFocus = true,
  }) => {
    const auth = useSelector((state) => state.auth);
    const [text, setText] = useState("");
    const onChangeText = (e) => {
      setText(e);
      onChange(e);
    };

    const onClick = useCallback(() => {
      if (text.length > 0) {
        onSubmit(text);
        setText("");
      }
    }, [text]);

    return (
      <div className="chatNew">
        {data?.dialog?.product?.id && (
          <a href={process.env.REACT_APP_SITE_URL + '/game/lot/' + data.dialog.product.id} target="_blank" className="box shadow-none d-flex align-items-center justify-content-between">
            <div>
              <h5 className="fw-7 mb-0">
                Объявление #{data.dialog.product.id}
              </h5>
              <p>{data.dialog.product.desc}</p>
            </div>
            <div>{customPrice(data.dialog.product.total)}</div>
          </a>
        )}
        <div className="chatNew-window">
          {data?.items?.length > 0 ? (
            <>
              {data.items.map((e) => (
                <Message
                  my={e.memberId === auth.user.id}
                  name="Альберт"
                  time={e.createdAt}
                  text={e.text}
                  view={e.view}
                  admin={e.type === "system"}
                />
              ))}
            </>
          ) : (
            <div className="w-100 py-5 text-center text-muted fs-09 d-flex flex-column align-items-center justify-content-center">
              {emptyText}
            </div>
          )}
        </div>
        <form>
          <Input
            autofocus={autoFocus}
            value={text}
            className="flex-1"
            placeholder="Введите сообщение"
            onChange={(e) => onChangeText(e)}
          />
          <a onClick={() => onClick()} className="btn-success">
            Отправить
          </a>
        </form>
      </div>
    );
  }
);

export default Chat;
