import React, { useCallback, useLayoutEffect, useState } from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { IoEllipsisVertical } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useSearchParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import CustomModal from "../../components/utils/CustomModal";
import { getImageURL } from "../../helpers/image";
import { deleteBanner, getBanners } from "../../services/banner";

const Ads = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [banners, setBanners] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const getData = useCallback(async () => {
    searchParams.set("type", 1);
    getBanners(searchParams)
      .then(
        (res) =>
          res &&
          setBanners((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setBanners((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onDelete = useCallback((id) => {
    deleteBanner(id)
      .then(() => {
        getData();
        NotificationManager.success("Баннер успешно удалена");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteBanner(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные баннеры успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, [selected]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  if (banners.loading) {
    return <Loader full />;
  }

  return (
    <Container fluid={true}>
      <Meta title="Рекламные баннеры" />
      <section className="box">
        <div className="d-flex align-items-center justify-content-between mb-4 w-100">
          <h5 className="fw-7">Рекламные баннеры</h5>
          <Select
            classNameContainer="ms-5"
            label="Сортировка"
            data={[
              { title: "Все", value: "" },
              { title: "Квадратные", value: "announcement" },
              { title: "Прямоугольные", value: "catalog" },
            ]}
            value={searchParams.get("style") ?? ""}
            onClick={(e) => {
              searchParams.set("style", e.value);
              setSearchParams(searchParams);
              getData();
            }}
          />
          <Link to="/ads/create" className="btn-primary-outline">
            Добавить баннер
          </Link>
        </div>
        {banners?.items?.length > 0 ? (
          <div className="d-flex flex-column">
            <Row>
              {banners.items.map((e) => (
                <Col md={4}>
                  <figure class="banner-mini mb-4">
                    <img
                      src={getImageURL({
                        path: e.media,
                        size: "full",
                        type: "banner",
                      })}
                      alt={e.title}
                    />
                    <figcaption className="d-flex justify-content-between align-items-center">
                      <Link class="text-transfer" to={"/ad/" + e.id}>
                        {e.title.length > 0 ? e.title : "Заголовок"}
                      </Link>
                      <div>
                        <Dropdown className="d-flex align-items-center">
                          <Dropdown.Toggle
                            as={React.forwardRef(
                              ({ children, onClick }, ref) => (
                                <Link
                                  ref={ref}
                                  className="py-0"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onClick(e);
                                  }}
                                >
                                  <IoEllipsisVertical size={20} />
                                </Link>
                              )
                            )}
                          />
                          <Dropdown.Menu align="end">
                            <Dropdown.Item as={Link} to={"/ad/" + e.id}>
                              Редактировать
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                setModalDelete({
                                  show: !modalDelete.show,
                                  id: e.id,
                                })
                              }
                            >
                              Удалить
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </figcaption>
                  </figure>
                </Col>
              ))}
            </Row>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center mb-4">
            <div className="my-5">
              <h3>Баннеров нет</h3>
              <div className="d-flex justify-content-center">
                <Link className="mt-3 btn btn-primary" to="/ads/create">
                  Добавить баннер
                </Link>
              </div>
            </div>
          </div>
        )}

        <CustomModal
          title={
            selected?.length > 0
              ? `Удаление ${selected.length} элементов`
              : "Удаление элемента"
          }
          show={modalDelete.show}
          setShow={(e) => setModalDelete({ show: e, id: false })}
          footer={
            <>
              <Button
                className="me-3"
                onClick={() =>
                  setModalDelete({ show: !modalDelete.show, id: false })
                }
              >
                Отмена
              </Button>
              <Button
                className="btn-danger"
                onClick={() =>
                  selected.length > 0
                    ? onDeleteSelected()
                    : modalDelete.id && onDelete(modalDelete.id)
                }
              >
                Удалить
              </Button>
            </>
          }
        >
          Вы точно хотите удалить баннер(-ы)?
        </CustomModal>
      </section>
    </Container>
  );
};

export default Ads;
