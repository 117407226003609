import moment from "moment";
import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Badge, Col, Row, Container } from "react-bootstrap";
import {
  IoChatboxOutline,
  IoCloseOutline,
  IoCreateOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import CustomModal from "../../components/utils/CustomModal";
import { getMembers } from "../../services/member";
import { deleteTask, editTask, getTasks } from "../../services/task";
import { updateNotification } from "../../store/reducers/notificationSlice";

const Reports = ({ userId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const inputRef = createRef();

  const taskNotification = useSelector((state) => state.notification.task);

  const [tasks, setTasks] = useState({
    loading: true,
    items: [],
  });
  const [members, setMembers] = useState({
    loading: true,
    items: [],
  });
  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    isShow: false,
    id: false,
  });


  const taskColumns = [
    {
      width: "130px",
      name: "Тип",
      align: "center",
      cell: (row) => (
        <Link to={`/task/${row?.id}`}>
          {row.type === "task" ? (
            <Badge bg="success">Обращение</Badge>
          ) : row.type === "word" ? (
            <Badge bg="warning">Стоп-слово</Badge>
          ) : (
            row.type === "report" && <Badge bg="danger">Жалоба</Badge>
          )}
        </Link>
      ),
    },
    {
      name: "Дата",
      selector: "createdAt",
      cell: (row) => (
        <>
          <span className="me-1">
            {moment(row.createdAt).format("DD.MM.YYYY")}
          </span>
          <span className="fw-7">{moment(row.createdAt).format("kk:mm")}</span>
        </>
      ),
    },

    {
      width: "130px",
      name: "Статус",
      align: "center",
      cell: (row) =>
        !row.status || row.status === "new" ? (
          <Badge bg="success">Новый</Badge>
        ) : row.status === "process" ? (
          <Badge bg="secondary">В процессе</Badge>
        ) : row.status === "ok" ? (
          <Badge bg="success">Выполнено</Badge>
        ) : (
          row.status === "close" && <Badge bg="danger">Закрыто</Badge>
        ),
    },
    {
      name: "Пользователь",
      cell: (row) => (
        <Link to={`/user/${row?.author?.id}`}>{row?.author?.firstName}</Link>
      ),
    },
    {
      align: "center",
      name: "Тема",
      cell: (row) => <Link to={`/task/${row?.id}`}>{row?.title}</Link>,
    },

    // {
    //   align: "right",
    //   name: "",
    //   cell: (row) => {
    //     const [member, setMember] = useState({ id: null, memberId: null });
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Select
    //           className="select-sm"
    //           onClick={(e) => {
    //             setMember({ id: row.id, memberId: e.value });
    //           }}
    //           value={member.memberId ?? row.memberId ?? ""}
    //           data={members.items.map((e) => ({
    //             title: e.firstName,
    //             value: e.id,
    //           }))}
    //         />
    //         <Button
    //           className="btn-primary ms-2 me-2 btn-sm"
    //           onClick={() => {
    //             editTask({ ...row, memberId: member.memberId });
    //             NotificationManager.success("Сотрудник задачи изменен");
    //             getData();
    //           }}
    //         >
    //           Взять
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   width: "80px",
    //   selector: "action",
    //   align: "right",
    //   cell: (row) => {
    //     return (
    //       <>
    //         <div className="d-flex align-items-center">
    //           <Link to={"/task/" + row.id} className="me-3">
    //             <IoCreateOutline size={22} />
    //           </Link>
    //         </div>
    //       </>
    //     );
    //   },
    // },
  ];

  const header = useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          <h5 className="fw-7">
            {selected.length > 0 ? `Выбрано ${selected.length}` : "Жалобы"}
          </h5>
        </div>
        <Select
          classNameContainer="ms-5"
          label="Сортировка"
          data={[
            { title: "По дате добавления: новые", value: "" },
            { title: "По дате добавления: старые", value: "createold" },
          ]}
          value={searchParams.get("sort") ?? ""}
          onClick={(e) => {
            searchParams.set("sort", e.value);
            setSearchParams(searchParams);
            onSearch();
          }}
        />
        <div className="search-box ms-3 me-5">
          <Input
            ref={inputRef}
            placeholder="Найти"
            className="w-100"
            onChange={(e) => {
              searchParams.set("text", e);
              setSearchParams(searchParams);
            }}
            rightIcon={() => <IoSearchOutline size={22} />}
            defaultValue={searchParams.get("text")}
            rightIconClick={() => onSearch()}
            onKeyDown={(e) => e === "Enter" && onSearch()}
          />
          {searchParams.get("text")?.length > 0 && (
            <Button
              className="btn-light ms-3"
              onClick={() => {
                searchParams.delete("text");
                setSearchParams(searchParams);
                onSearch();
                if (inputRef.current) {
                  inputRef.current.value = "";
                }
              }}
            >
              <IoCloseOutline size={22} />
            </Button>
          )}
        </div>
        {/* <div className="d-flex align-items-center">
          <Button
            disabled={selected.length === 0}
            className="btn-light"
            onClick={() => setModalDelete({ show: true, id: false })}
          >
            <IoTrashOutline size={18} />
          </Button>
        </div> */}
      </div>
    );
  }, [selected, searchParams, modalDelete]);

  const getData = useCallback(async () => {
    if (!userId) {
      dispatch(updateNotification({ task: -1 }));
    }
    getTasks(searchParams)
      .then((res) => {
        setTasks((prev) => ({
          ...prev,
          loading: false,
          ...res,
        }));
      })
      .finally(() => setTasks((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  useLayoutEffect(() => {
    searchParams.set("type", "report");
    setSearchParams(searchParams);
    if (userId) {
      searchParams.set("userId", userId);
      setSearchParams(searchParams);
    }
    getData();
  }, [searchParams.get("page"), taskNotification]);

  const clickDelete = (id) => {
    deleteTask(id).then(() => getData());
    setModalDelete({ isShow: false, id: false });
  };

  if (tasks.loading) {
    return <Loader full />;
  }

  return (
    <Container fluid={true}>
      <Meta title="Задачи" />
      <section className="box">
        <DataTable
          columns={taskColumns}
          onChange={(items) => setSelected(items)}
          data={tasks.items}
          header={!userId ? header : null}
          selectable
          pagination={tasks.pagination}
        />
      </section>
      <CustomModal
        title={`Удаление ${modalDelete.id ? "#" + modalDelete.id : ""}`}
        isShow={modalDelete.isShow}
        setShow={(e) => setModalDelete({ isShow: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={(e) =>
                setModalDelete({ isShow: !modalDelete.isShow, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => modalDelete.id && clickDelete(modalDelete.id)}
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить задачу?
      </CustomModal>
    </Container>
  );
};

export default Reports;
