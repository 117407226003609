import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Badge, Col, Row } from "react-bootstrap";
import {
  IoAdd,
  IoCloseOutline,
  IoCreateOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { deletePromo, getPromos } from "../../services/promo";

const Promos = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const inputRef = createRef();
  const [promos, setPromos] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const сolumns = [
    {
      name: "Название",
      selector: "title",
    },
    {
      name: "Описание",
      selector: "desc",
    },
    {
      width: "100px",
      name: "Статус",
      selector: "status",
      cell: (row) =>
        row.status === 0 ? (
          <Badge bg="secondary">Архив</Badge>
        ) : (
          <Badge bg="success">Активно</Badge>
        ),
    },
    {
      name: "Сумма",
      selector: "value",
    },
    {
      width: "100px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <Link to={"/promo/" + row.id} className="me-3">
            <IoCreateOutline size={22} />
          </Link>
          <a
            onClick={() =>
              setModalDelete({ show: !modalDelete.show, id: row.id })
            }
            className="me-2"
          >
            <IoTrashOutline size={20} className="text-danger" />
          </a>
        </div>
      ),
    },
  ];

  const getData = useCallback(async () => {
    getPromos(searchParams)
      .then(
        (res) =>
          res &&
          setPromos((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setPromos((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onDelete = useCallback((id) => {
    deletePromo(id)
      .then(() => {
        getData();
        NotificationManager.success("Промокод успешно удален");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  const onDeleteSelected = useCallback(() => {
    deletePromo(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные пользователи успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, [selected]);

  if (promos.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="Промокоды" />
      <section className="box">
        <div className="d-flex align-items-center mb-4">
          <h5>Промокоды</h5>
          <Link to="/promos/create" className="btn-primary ms-5">
            Добавить промокод
          </Link>
          <div className="search-box ms-3">
            <Input
              // readOnly={false}
              ref={inputRef}
              placeholder="Найти"
              className="w-100"
              onChange={(e) => {
                searchParams.set("text", e);
                setSearchParams(searchParams);
              }}
              defaultValue={searchParams.get("text")}
              onKeyDown={(e) => e === "Enter" && onSearch()}
            />
            {searchParams.get("text")?.length > 0 && (
              <Button
                className="btn-light ms-3"
                onClick={() => {
                  searchParams.delete("text");
                  setSearchParams(searchParams);
                  onSearch();
                  if (inputRef.current) {
                    inputRef.current.value = "";
                  }
                }}
              >
                <IoCloseOutline size={22} />
              </Button>
            )}
          </div>
        </div>
        <DataTable
          columns={сolumns}
          onChange={(items) => setSelected(items)}
          data={promos.items}
          selectable
          pagination={promos.pagination}
        />
      </section>
      <CustomModal
        title={`Удаление ${modalDelete.id ? "#" + modalDelete.id : ""}`}
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={(e) =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить пользователя?
      </CustomModal>
    </>
  );
};

export default Promos;
