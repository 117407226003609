import React, { useCallback, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import Input from "../../components/UI/Input";
import Select from "../../components/UI/Select";
import Textarea from "../../components/UI/Textarea";
import { promos } from "../../helpers/all";
import { createPromo } from "../../services/promo";

const PromoCreate = () => {
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: "onSubmit",
    defaultValues: {
      type: promos[0].value,
      desc: promos[0].desc,
      error: promos[0].error,
      status: 1,
    },
  });

  const data = useWatch({ control });

  useEffect(() => {
    if (data?.type && promos?.length > 0) {
      let promo = promos.find((e) => e.value === data.type);
      if (promo) {
        reset({
          ...data,
          type: promo.value,
          desc: promo.desc,
          error: promo.error,
        });
      }
    }
  }, [data?.type]);

  const onSubmit = useCallback((data) => {
    createPromo(data)
      .then(() => {
        NotificationManager.success("Промокод успешно создан");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  return (
    <>
      <Meta title="Создать промокод" />
      <section className="box">
        <div>
          <Link
            to="/promos"
            className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
          >
            <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
          </Link>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3>Создать промокод</h3>
          <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
            Сохранить
          </Button>
        </div>

        <Row>
          <Col md={12}>
            <div className="mb-4">
              <Select
                label="Тип"
                title="Тип"
                value={data?.type ?? promos[0].value}
                data={promos}
                onClick={(e) => setValue("type", e.value)}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-4">
              <Input
                label="Промокод"
                name="title"
                placeholder="Например `hello`"
                errors={errors}
                register={register}
                validation={{
                  required: "Обязательное поле",
                  maxLength: {
                    value: 100,
                    message: "Максимально 100 символов",
                  },
                }}
              />
              <small className="text-muted fs-08">
                Длина промокода не более 100 символов. Пробелы будут удалены.
                Регистр не учитывается.
              </small>
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3">
              <Textarea
                rows={6}
                label="Описание"
                name="desc"
                errors={errors}
                register={register}
              />
              <small className="text-muted fs-08">
                Описание видит только администратор
              </small>
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-4">
              <Input
                label="Текст ошибки"
                name="error"
                placeholder="Введите текст ошибки"
                errors={errors}
                register={register}
                validation={{
                  maxLength: {
                    value: 250,
                    message: "Максимально 250 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Input
                defaultValue={data.value ?? 0}
                label="Сумма"
                type="number"
                name="value"
                errors={errors}
                register={register}
                validation={{
                  max: {
                    value: 1000000,
                    message: "Максимально 1 000 000",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={6}>
            <Form.Check className="mb-2">
              <Form.Check.Input
                type="checkbox"
                name="status"
                id="status"
                defaultChecked={data.status}
                {...register("status")}
              />
              <Form.Check.Label htmlFor="status" className="ms-2">
                Активный\Неактивный промокод
              </Form.Check.Label>
            </Form.Check>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default PromoCreate;
