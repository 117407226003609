import moment from "moment";
import React, { useState } from "react";
import {
  IoCheckmarkDoneOutline,
  IoCheckmarkOutline,
  IoBanSharp,
} from "react-icons/io5";

const Message = ({ my, time, text, name, view = false, admin = false }) => {
  time = time
    ? moment(time).format("DD MMMM YYYY kk:mm")
    : moment().format("DD MMMM YYYY kk:mm");
  const [isBanned, setIsBanned] = useState(false);

  return (
    <div className={my ? "message my" : "message"}>
      {admin == false && (
        <div className="message-avatar">
          <img src="/images/avatar.png" alt={name} />
        </div>
      )}
      <div className="message-main">
        <div className="message-main-top">
          {/* {
            (admin) &&
            <span className="orange ms-3">Сообщение от админитрации</span>
          } */}
          <time>{time}</time>
          {admin == false && (
            <button
              type="button"
              className={isBanned ? "ban banned" : "ban"}
              onClick={() => setIsBanned(true)}
            >
              <IoBanSharp />
            </button>
          )}
        </div>
        <div
          className={
            isBanned ? "message-main-text banned" : "message-main-text"
          }
          data-admin={admin}
        >
          <p>{text}</p>
          {view ? (
            <IoCheckmarkDoneOutline className="viewed" />
          ) : (
            <IoCheckmarkOutline />
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;
