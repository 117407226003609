import moment from "moment";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {
  HiEllipsisHorizontal,
  HiOutlinePencilSquare,
  HiOutlineTrash,
} from "react-icons/hi2";
import { Link } from "react-router-dom";
import { getImageURL } from "../helpers/image";

const ArticlesListItem = ({ data, onDelete }) => {
  return (
    <li className="articles-list-item">
      <img
        src={
          data?.media
            ? getImageURL({ path: data.media, type: "blog" })
            : "/images/img-replacement.jpg"
        }
        alt="replacement"
      />
      <div className="articles-list-item-info">
        <div>
          <h6>{data.title}</h6>
          <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
        </div>
        <time>{moment(data.updatedAt).fromNow()}</time>
      </div>
      <Dropdown as="div" className="articles-list-item-menu">
        <Dropdown.Toggle variant="none">
          <HiEllipsisHorizontal />
        </Dropdown.Toggle>
        <Dropdown.Menu as="ul" className="list-unstyled">
          <Dropdown.Item as="li">
            <Link to={"/blog/" + data.id}>
              <span>Изменить</span>
              <HiOutlinePencilSquare className="ms-3" />
            </Link>
          </Dropdown.Item>
          <Dropdown.Item as="li">
            <button type="button" onClick={onDelete}>
              <span>Удалить</span>
              <HiOutlineTrash className="ms-3" />
            </button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </li>
  );
};

export default ArticlesListItem;
