const accountTypes = [
      {
            title: "Нет",
            value: "",
      },
      {
            title: "Физ.Лицо",
            value: "individual",
      },
      {
            title: "Самоз-ый",
            value: "selfemployed",
      },
      {
            title: "ИП",
            value: "ip",
      },
      {
            title: "ООО",
            value: "llc",
      },
];

const promos = [
      {
            "title": "Одноразовый для всех пользователей",
            "value": "user_all_one",
            "desc": "Единоразовое добавление промокода для всех пользователей",
            "error": "Вы уже использовали данный промокод"
      },
      {
            "title": "Одноразовый для одного пользователя",
            "value": "user_one_one",
            "desc": "Единоразовое добавление промокода для одного пользователя",
            "error": "Вы уже использовали данный промокод"
      }
]


const accountType = (value = 'individual') => {
      let info = accountTypes.find((e) => e.value.toLowerCase() === value.toLowerCase());
      return info?.title && value ? info : accountTypes[0];
};

export { accountTypes, accountType, promos };
