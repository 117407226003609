import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getPartners = async (data) => {
  const response = await $authApi.get(apiRoutes.PARTNERS, {
    params: data,
  });

  return response?.data;
};

const getPartner = async (id) => {
  const response = await $authApi.get(apiRoutes.PARTNER, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createPartner = async (data) => {
  const response = await $authApi.postForm(apiRoutes.PARTNER_CREATE, data);
  return response?.data;
};

const editPartner = async (data) => {
  const response = await $authApi.postForm(apiRoutes.PARTNER_EDIT, data);
  return response?.data;
};

const deletePartner = async (ids) => {
  const response = await $authApi.delete(apiRoutes.PARTNERS, {
    data: { ids },
  });
  return response?.data;
};

export { getPartners, getPartner, createPartner, editPartner, deletePartner };
