import React, { useRef, useState } from "react";
import { IoTrashOutline } from "react-icons/io5";
import { getImageURL } from "../helpers/image";
import ReactPlayer from "react-player";

const DragDropFile = ({
  onChange,
  accept = "image/*", // Устанавливаем значение по умолчанию для accept
  multiple = true, // Устанавливаем значение по умолчанию для multiple
  file,
  title = "Выберите изображение",
  onRemove, // Добавляем функцию onRemove
}) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const [previewVideo, setPreviewVideo] = useState(null);
  const [previewImg, setPreviewImg] = useState(false);
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      onChange(file);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (file.type.startsWith('video/')) {
        setPreviewVideo(URL.createObjectURL(file)); // Устанавливаем превью видео
        onChange(file);
      }
      else {
        setPreviewImg(true)
        onChange(e.target.files);
      }

    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  // Функция для удаления файла
  const handleRemove = () => {
    onRemove();
    setPreviewVideo(null); // Очищаем превью при удалении файла
  };

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        className="d-none"
        accept={accept}
        multiple={multiple}
        onChange={handleChange}
      />
      {file ? (
        <div className="position-relative">
          {accept == "video/*" ? (
            <ReactPlayer
              url={previewVideo
                ? previewVideo
                : getImageURL({
                  path: file,
                  type: "instruction",
                  size: "full",
                })}
              width="100%"
              controls={true}
            />
          ) : (
            <img
              src={
                previewImg
                  ? file
                  : getImageURL({
                    path: file,
                    type: "instruction",
                    size: "full",
                  })
              }
              className="upload-box-img"
            />
          )}
          <div className="d-flex flex-row mt-2">
            <a
              className="btn btn-primary-outline w-100 me-2 upload-box-btn"
              onClick={onButtonClick}
            >
              Изменить
            </a>
            <a
              className="btn btn-danger-outline upload-box-btn"
              onClick={handleRemove}
            >
              <IoTrashOutline size={24} />
            </a>
          </div>
        </div>
      ) : (
        <>
          <div
            className="position-relative"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <div
              htmlFor="input-file-upload"
              className={
                "upload-box product d-flex flex-column align-items-center justify-content-center" +
                (dragActive ? " active" : "")
              }
            >
              <img src="/images/upload-image-product.svg" height={100} />
              <h5 className="mt-3 mb-2 fs-09 fw-7">{title}</h5>
              <p className="text-muted text-center fs-08">
                Перетащите файл сюда или нажмите{" "}
                <a className="text-success" onClick={onButtonClick}>
                  обзор
                </a>{" "}
                чтобы загрузить файл с компьютера
              </p>
              <a
                className="mt-3 btn btn-sm btn-primary-outline"
                onClick={onButtonClick}
              >
                Выбрать файл
              </a>
            </div>
            {dragActive && (
              <div
                className="drag-file-element"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DragDropFile;