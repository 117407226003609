import React, { useCallback, useState } from "react";
import { Container } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import { createBlog } from "../../services/blog";
import CustomEditor from "../../components/editor/CustomEditor";
import Input from "../../components/UI/Input";
import DragDropFile from "../../components/DragDropFile";
import ImageCropper from "../../components/Cropper";

const BlogCreate = () => {
  const navigate = useNavigate();
  const [editImageBanner, setEditImageBanner] = useState({
    show: false,
    data: [],
  });
  const {
    control,
    register,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const onSubmit = useCallback(() => {
    createBlog(data)
      .then(() => {
        NotificationManager.success("Новость успешно создана");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, [data]);

  return (
    <Container fluid={true}>
      <Meta title="Создать новость" />
      <section className="box">
        <div className="d-flex justify-content-between align-items-center">
          <Link
            to="/blogs"
            className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
          >
            <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
          </Link>
          <button
            className="btn-primary"
            disabled={!isValid}
            onClick={() => onSubmit()}
          >
            Сохранить изменения
          </button>
        </div>
        <h3 className="mb-4">Создать новость</h3>
        <div className="mb-3">
          <DragDropFile
            title="Выбрать изображение"
            file={data.media}
            onChange={(e) =>
              setEditImageBanner((info) => ({
                show: !info.show,
                data: e,
              }))
            }
          />
          <ImageCropper
            file={editImageBanner.data[0]}
            show={editImageBanner.show}
            aspect={265 / 149}
            setShow={(e) =>
              setEditImageBanner((info) => ({ ...info, show: e }))
            }
            onComplete={(e) => {
              e && setValue("file", e.file);
              e && setValue("media", e.blob);
            }}
          />
        </div>
        <div className="mb-3">
          <Input
            label="Название"
            name="title"
            placeholder="Заголовок"
            errors={errors}
            register={register}
            validation={{
              required: "Обязательное поле",
            }}
          />
        </div>
        <CustomEditor
          content={data.content}
          onChange={(e) => setValue("content", e)}
        />
      </section>
    </Container>
  );
};

export default BlogCreate;
