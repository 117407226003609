import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useState,
} from "react";
import { Container } from "react-bootstrap";
import { IoCloseOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useSearchParams } from "react-router-dom";
import InstructionItem from "../../components/InstructionItem";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { deleteInstruction, getInstructions } from "../../services/instruction";

const Instructions = () => {
  const inputRef = createRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [categories, setInstructions] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const getData = useCallback(async () => {
    getInstructions(searchParams)
      .then((res) =>
        setInstructions((prev) => ({
          ...prev,
          loading: false,
          ...res,
        }))
      )
      .finally(() => setInstructions((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onDelete = useCallback((id) => {
    deleteInstruction(id)
      .then(() => {
        getData();
        NotificationManager.success("Инструкция успешно удалена");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteInstruction(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные инструкции успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при запросе"
        )
      );
  }, [selected]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  if (categories.loading) {
    return <Loader full />;
  }

  return (
    <Container fluid={true}>
      <Meta title="Инструкции" />
      <section className="box">
        <div className="d-flex align-items-center mb-4">
          <h5>Инструкции</h5>
          <Link to="/instructions/create" className="btn-primary ms-5">
            Добавить инструкцию
          </Link>
          <div className="search-box ms-3">
            <Input
              // readOnly={false}
              ref={inputRef}
              placeholder="Найти"
              className="w-100"
              onChange={(e) => {
                searchParams.set("text", e);
                setSearchParams(searchParams);
              }}
              defaultValue={searchParams.get("text")}
              onKeyDown={(e) => e === "Enter" && onSearch()}
            />
            {searchParams.get("text")?.length > 0 && (
              <Button
                className="btn-light ms-3"
                onClick={() => {
                  searchParams.delete("text");
                  setSearchParams(searchParams);
                  onSearch();
                  if (inputRef.current) {
                    inputRef.current.value = "";
                  }
                }}
              >
                <IoCloseOutline size={22} />
              </Button>
            )}
          </div>
        </div>
        {/* <ul className="articles-list">
          {categories.items.map((item, index) => {
            return (
              <ArticlesListItem
                key={index}
                data={item}
                onDelete={() =>
                  setModalDelete({ show: !modalDelete.show, id: item.id })
                }
              />
            );
          })}
        </ul>
        <div className="py-2">
          <NavPagination />
        </div> */}

        <DataTable
          type="custom"
          data={categories.items}
          renderItem={(item, index) => (
            <InstructionItem
              key={index}
              data={item}
              onDelete={() =>
                setModalDelete({ show: !modalDelete.show, id: item.id })
              }
            />
          )}
          pagination={categories.pagination}
        />
        <CustomModal
          title={
            selected.length > 0
              ? `Удаление ${selected.length} элементов`
              : "Удаление элемента"
          }
          show={modalDelete.show}
          setShow={(e) => setModalDelete({ show: e, id: false })}
          footer={
            <>
              <Button
                className="me-3"
                onClick={() =>
                  setModalDelete({ show: !modalDelete.show, id: false })
                }
              >
                Отмена
              </Button>
              <Button
                className="btn-primary"
                onClick={() =>
                  selected.length > 0
                    ? onDeleteSelected()
                    : modalDelete.id && onDelete(modalDelete.id)
                }
              >
                Удалить
              </Button>
            </>
          }
        >
          Вы точно хотите удалить инструкцию?
        </CustomModal>
      </section>
    </Container>
  );
};

export default Instructions;
