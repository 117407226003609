import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getInstructions = async (data) => {
  const response = await $authApi.get(apiRoutes.INSTRUCTIONS, {
    params: data,
  });

  return response?.data;
};

const getInstruction = async (id) => {
  const response = await $authApi.get(apiRoutes.INSTRUCTION, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createInstruction = async (data) => {
  const response = await $authApi.postForm(apiRoutes.INSTRUCTION_CREATE, data);
  return response?.data;
};

const editInstruction = async (data) => {
  const response = await $authApi.postForm(apiRoutes.INSTRUCTION_EDIT, data);
  return response?.data;
};

const deleteInstruction = async (ids) => {
  const response = await $authApi.delete(apiRoutes.INSTRUCTIONS, {
    data: { ids },
  });
  return response?.data;
};

export { getInstructions, getInstruction, createInstruction, editInstruction, deleteInstruction };
