import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getBlogs = async (data) => {
  const response = await $authApi.get(apiRoutes.BLOGS, {
    params: data,
  });
  return response?.data;
};
const getBlog = async (id) => {
  const response = await $authApi.get(apiRoutes.BLOG, {
    params: {
      id,
    },
  });
  return response?.data;
};
const editBlog = async (data) => {
  const response = await $authApi.postForm(apiRoutes.BLOG_EDIT, data);
  return response?.data;
};
const deleteBlog = async (id) => {
  const response = await $authApi.delete(apiRoutes.BLOGS, {
    data: { id },
  });
  return response?.data;
};
const createBlog = async (data) => {
  const response = await $authApi.postForm(apiRoutes.BLOGS, data);
  return response?.data;
};
export {
  getBlogs,
  getBlog,
  editBlog,
  deleteBlog,
  createBlog,
};
