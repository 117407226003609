import React from "react";
import { Route, Routes } from "react-router-dom";

import NotFound from "../pages/NotFound";

import Orders from "../pages/order";
import OrderEdit from "../pages/order/Edit";

import Notifications from "../pages/notification";
import NotificationCreate from "../pages/notification/Create";

import Categories from "../pages/category";
import CategoryCreate from "../pages/category/Create";
import CategoryEdit from "../pages/category/Edit";

import Products from "../pages/product";
import ProductCreate from "../pages/product/Create";
import ProductEdit from "../pages/product/Edit";

import Params from "../pages/param";
import ParamCreate from "../pages/param/Create";
import ParamEdit from "../pages/param/Edit";

import Users from "../pages/user";
import UserEdit from "../pages/user/Edit";

import Banners from "../pages/banner";
import BannerCreate from "../pages/banner/Create";
import BannerEdit from "../pages/banner/Edit";

import Documents from "../pages/document";
import DocumentCreate from "../pages/document/Create";
import DocumentEdit from "../pages/document/Edit";

import Members from "../pages/member";
import MemberEdit from "../pages/member/Edit";

import Account from "../pages/account";

import { useSelector } from "react-redux";
// import Dialogs from "../pages/dialog";
import Payment from "../pages/invoice";
import Invoices from "../pages/invoice/Invoices";
import Invoice from "../pages/invoice/View";
import MemberCreate from "../pages/member/Create";
import Transactions from "../pages/transaction";
import Withdrawals from "../pages/withdrawal";
// import StopWord from "../pages/stopWord";
// import CreateStopWord from "../pages/stopWord/Create";
// import EditStopWord from "../pages/stopWord/Edit";
import Tasks from "../pages/task";
import Reviews from "../pages/reviews";
// import ActionsLog from "../pages/member/ActionsLog";
import Histories from "../pages/history";
import EditTask from "../pages/task/Edit";
import Ads from "../pages/ad";
import AdEdit from "../pages/ad/Edit";
import AdCreate from "../pages/ad/Create";
import Blogs from "../pages/blog";
import BlogEdit from "../pages/blog/Edit";
import BlogCreate from "../pages/blog/Create";
import Partners from "../pages/partner";
import PartnerEdit from "../pages/partner/Edit";
import PartnerCreate from "../pages/partner/Create";
import Instructions from "../pages/instructions";
import InstructionEdit from "../pages/instructions/Edit";
import InstructionCreate from "../pages/instructions/Create";
import Promos from "../pages/promo";
import PromoCreate from "../pages/promo/Create";
import PromoEdit from "../pages/promo/Edit";
import Reports from "../pages/task/Report";
const AdminRouter = () => {
  const role = useSelector((state) => state?.auth?.user?.role);

  return (
    <Routes>
      <Route path="/">
        <Route path="account" element={<Account />} />
        {/* <Route path="stopwords" element={<StopWord />} />
        <Route path="stopwords/create" element={<CreateStopWord />} />
        <Route path="stopword/:stopwordId" element={<EditStopWord />} /> */}
        <Route path="histories" element={<Histories />} />
        {role != 4 && (
          <>
            <Route path="tasks" element={<Tasks />} />
            <Route path="task/:taskId" element={<EditTask />} />

            <Route path="report" element={<Reports />} />
            <Route path="report/:taskId" element={<EditTask />} />

            {/* <Route path="dialogs" element={<Dialogs />} />
            <Route path="dialogs/:dialogId" element={<Dialogs />} /> */}

            <Route path="payment" element={<Payment />} />
            <Route path="invoices" element={<Invoices />} />
            <Route path="invoice/:invoiceId" element={<Invoice />} />

            <Route path="notifications" element={<Notifications />} />
            <Route
              path="notifications/create"
              element={<NotificationCreate />}
            />

            <Route path="users" element={<Users />} />
            <Route path="user/:userId" element={<UserEdit />} />
            <Route path="members" element={<Members />} />
            {/* <Route path="members/log" element={<ActionsLog />} /> */}
            {role == 1 && (
              <>
                <Route path="members/create" element={<MemberCreate />} />
                <Route path="member/:memberId" element={<MemberEdit />} />
              </>
            )}

            <Route path="reviews" element={<Reviews />} />

            <Route path="reports" element={<Reports />} />
          </>
        )}
        <Route path="promos" element={<Promos />} />
        <Route path="promo/:promoId" element={<PromoEdit />} />
        <Route path="promos/create" element={<PromoCreate />} />

        <Route path="products/:service" element={<Products />} />
        <Route path="products/:service/:type" element={<Products />} />
        <Route path="product/:productId" element={<ProductEdit />} />
        <Route path="products/create" element={<ProductCreate />} />

        <Route path="blogs" element={<Blogs />} />
        <Route path="blog/:blogId" element={<BlogEdit />} />
        <Route path="blogs/create" element={<BlogCreate />} />

        <Route path="banners" element={<Banners />} />
        <Route path="banner/:bannerId" element={<BannerEdit />} />
        <Route path="banners/create" element={<BannerCreate />} />

        <Route path="partners" element={<Partners />} />
        <Route path="partner/:partnerId" element={<PartnerEdit />} />
        <Route path="partners/create" element={<PartnerCreate />} />

        <Route path="instructions" element={<Instructions />} />
        <Route
          path="instruction/:instructionId"
          element={<InstructionEdit />}
        />
        <Route path="instructions/create" element={<InstructionCreate />} />

        <Route path="documents" element={<Documents />} />
        <Route path="document/:documentId" element={<DocumentEdit />} />
        <Route path="documents/create" element={<DocumentCreate />} />

        <Route path="ads" element={<Ads />} />
        <Route path="ad/:bannerId" element={<AdEdit />} />
        <Route path="ads/create" element={<AdCreate />} />

        <Route path="categories" element={<Categories />} />
        <Route path="category/:categoryId" element={<CategoryEdit />} />
        <Route path="categories/create" element={<CategoryCreate />} />

        <Route path="params" element={<Params />} />
        <Route path="param/:paramId" element={<ParamEdit />} />
        <Route path="params/create" element={<ParamCreate />} />

        {role != 4 && (
          <>
            <Route path="deals" element={<Orders />} />
            <Route path="deal/:orderId" element={<OrderEdit />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="transaction/:transactionId" element={<OrderEdit />} />
            <Route path="withdrawals" element={<Withdrawals />} />
            <Route path="withdrawal/:withdrawalId" element={<OrderEdit />} />
          </>
        )}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AdminRouter;
