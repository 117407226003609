import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
  createRef,
} from "react";
import {
  HiMagnifyingGlass,
  HiCheckCircle,
  HiMiniCheckCircle,
} from "react-icons/hi2";
import { Link, useSearchParams } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import DataTable from "../../components/DataTable";
import Input from "../../components/UI/Input";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { getImageURL } from "../../helpers/image";
import { deleteUser, getUsers } from "../../services/user";
import SearchForm from "../../components/forms/SearchForm";
import { IoCloseOutline, IoTrashOutline } from "react-icons/io5";
import { accountType, accountTypes } from "../../helpers/all";
import moment from "moment";
import Select from "../../components/UI/Select";
// import socket from "../../config/socket";

const Users = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const inputRef = createRef();
  const [users, setUsers] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const userColumns = [
    {
      name: "Фото",
      selector: "nickname",
      cell: (row) => (
        <a
          href={process.env.REACT_APP_SITE_URL + "/user/" + row.id}
          target="_blank"
          className="d-flex align-items-center"
        >
          <div className="position-relative me-2">
            {(row.status === -1 || row.blockedEnd) && (
              <div className="blocked-avatar">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    fill="#FF0000"
                    d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
                  />
                  <path
                    fill="#FF0000"
                    d="M18.6038 3.01136C19.2189 2.39628 20.2161 2.39628 20.8312 3.01136C21.4463 3.62643 21.4463 4.62367 20.8312 5.23874L5.23947 20.8304C4.6244 21.4455 3.62716 21.4455 3.01209 20.8304C2.39701 20.2154 2.39701 19.2181 3.01209 18.6031L18.6038 3.01136Z"
                  />
                </svg>
              </div>
            )}
            {row?.status == 1 && (
              <HiMiniCheckCircle size={15} className="avatar-verified" />
            )}
            <img
              src={getImageURL({ path: row.media, type: "user" })}
              width={30}
              height={30}
              className="mini-img"
            />
          </div>
        </a>
      ),
    },
    {
      name: "№",
      selector: "id",
      cell: (row) => (
        <Link to={"/user/" + row.id} className="fs-09">
          {row.id}
        </Link>
      ),
    },
    {
      name: "Тип",
      selector: "id",
      cell: (row) => (
        <Link to={"/user/" + row.id} className="fs-09">
          {accountType(row?.data?.type)?.title}
        </Link>
      ),
    },
    {
      name: "Эл. почта",
      selector: "email",
      cell: (row) => (
        <Link to={"/user/" + row.id} className="fs-09">
          {row.email}
        </Link>
      ),
    },
    {
      name: "Объявления",
      align: "center",
      selector: "product",
    },
    {
      name: "Жалобы",
      align: "center",
      selector: "report",
    },
    {
      name: "Дата рег-ции",
      selector: "createdAt",
      cell: (row) => (
        <Link to={"/user/" + row.id} className="fs-09">
          {moment(row.createdAt).format("DD.MM.YYYY kk:mm")}
        </Link>
      ),
    },
  ];

  const getData = useCallback(async () => {
    getUsers(searchParams)
      .then(
        (res) =>
          res &&
          setUsers((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setUsers((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  const header = useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5>Пользователи</h5>
        <Select
          classNameContainer="ms-3"
          label="Тип"
          data={accountTypes}
          value={searchParams.get("type") ?? ""}
          onClick={(e) => {
            searchParams.set("type", e.value);
            setSearchParams(searchParams);
            onSearch();
          }}
        />
        <Select
          classNameContainer="ms-3"
          label="Жалобы"
          data={[
            { title: "Все", value: "" },
            { title: "Большее сверху", value: "desc" },
            { title: "Меньшее сверху", value: "asc" },
          ]}
          value={searchParams.get("report") ?? ""}
          onClick={(e) => {
            searchParams.set("report", e.value);
            setSearchParams(searchParams);
            onSearch();
          }}
        />
        <div className="search-box ms-3">
          <Input
            ref={inputRef}
            placeholder="Найти"
            className="w-100"
            onChange={(e) => {
              searchParams.set("text", e);
              setSearchParams(searchParams);
            }}
            defaultValue={searchParams.get("text")}
            onKeyDown={(e) => e === "Enter" && onSearch()}
          />
          {searchParams.get("text")?.length > 0 && (
            <Button
              className="btn-light ms-3"
              onClick={() => {
                searchParams.delete("text");
                setSearchParams(searchParams);
                onSearch();
                if (inputRef.current) {
                  inputRef.current.value = "";
                }
              }}
            >
              <IoCloseOutline size={22} />
            </Button>
          )}
        </div>
        {/* <div className="d-flex align-items-center justify-content-between">
          <div>
            <h5 className="fw-7">
              {selected.length > 0
                ? `Выбрано ${selected.length}`
                : "Пользователи"}
            </h5>
          </div>
          <div>
            <Button
              disabled={selected.length === 0}
              className="btn-light"
              onClick={() => setModalDelete({ show: true, id: false })}
            >
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
        <div>
          <Row>
            <Col md={3}>
              <Select
                classNameContainer="w-100"
                label="Статус"
                data={[
                  { title: "Все", value: "" },
                  { title: "Заблокированные", value: "blocked" },
                  { title: "Подтвержденные", value: "verified" },
                ]}
                value={searchParams.get("status") ?? ""}
                onClick={(e) => {
                  searchParams.set("status", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={3}>
              <Select
                classNameContainer="w-100"
                label="Сортировка"
                data={[
                  { title: "По дате регистрации: новые", value: "datenew" },
                  { title: "По дате регистрации: старые", value: "dateold" },
                  { title: "Комиссия: больше", value: "commissionmore" },
                  { title: "Комиссия: меньше", value: "commissionless" },
                  { title: "Объявления: больше", value: "adsmore" },
                  { title: "Объявления: меньше", value: "adsless" },
                ]}
                value={searchParams.get("sort") ?? "datenew"}
                onClick={(e) => {
                  searchParams.set("sort", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={6}>
              
            </Col>
          </Row>
        </div> */}
      </div>
    );
  }, [selected, searchParams, modalDelete]);
  //   useLayoutEffect(() => {
  //     socket.on("users/" + auth.user.id, (data) => {
  //       if (data?.status) {
  //         let newUsers = users.items.map((e) => {
  //           if (e.id === data.id) {
  //             return data;
  //           }
  //           return e;
  //         });
  //         if (newUsers) {
  //           setUsers((e) => ({ ...e, items: newUsers }));
  //         }
  //       }
  //     });
  //     return () => socket.off("users/" + auth.user.id);
  //   }, [users.items]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  const onDeleteSelected = useCallback(() => {
    deleteUser(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные пользователи успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, [selected]);

  if (users.loading) {
    return <Loader full />;
  }

  return (
    <Container fluid={true}>
      <Meta title="Пользователи" />
      <section className="box">
        <DataTable
          columns={userColumns}
          onChange={(items) => setSelected(items)}
          data={users.items}
          header={header}
          selectable
          pagination={users.pagination}
        />
      </section>
      <CustomModal
        title={`Удаление ${modalDelete.id ? "#" + modalDelete.id : ""}`}
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={(e) =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить пользователя?
      </CustomModal>
    </Container>
  );
};

export default Users;
