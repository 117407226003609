import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import {
  Badge,
  Container,
  Dropdown,
  Nav,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import {
  IoNotifications,
  IoSearch,
  IoMenu,
  IoCloseCircle,
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import useIsMobile from "../hooks/isMobile";
// import MobileNav from "./MobileNav";

import { logout } from "../services/auth";
import Menu from "./Menu";

import Button from "./UI/Button";
import CustomModal from "./utils/CustomModal";

const Date = () => {
  const [date, setDate] = useState(moment().format("DD MMM YYYY kk:mm:ss"));

  const updateTime = () => setDate(moment().format("DD MMM YYYY kk:mm:ss"));

  useEffect(() => {
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  return date;
};

const Header = () => {
  // const isMobile = useIsMobile();
  const state = useSelector((state) => state);

  const [isShowBurgerMenu, setShowBurgerMenu] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closeBurgerMenu = useCallback(() => setShowBurgerMenu(false), []);
  const [showLogout, setShowLogout] = useState(false);

  return (
    <>
      <header>
        <Container fluid className="h-100">
          <nav>
            {/* <form action="" className="d-none d-lg-flex">
              <input type="search" placeholder="Найти что-то нужное " />
              <button type="submit">
                <IoSearch />
              </button>
            </form>
            <button className="btn-icon d-lg-none">
              <IoSearch />
            </button> */}
            <Dropdown className="ms-3 ms-sm-4">
              <Dropdown.Toggle
                as={React.forwardRef(({ children, onClick }, ref) => (
                  <Nav.Link
                    ref={ref}
                    className="btn-icon"
                    onClick={(e) => {
                      e.preventDefault();
                      onClick(e);
                    }}
                  >
                    <IoNotifications />
                    {/* <div className="counter">
                      12
                    </div> */}
                    {/* {state?.auth?.user?.notificationCount > 0 && (
                      <Badge pill className="ms-2" bg="danger">
                        {state.auth.user.notificationCount}
                      </Badge>
                    )} */}
                  </Nav.Link>
                ))}
              />
              <Dropdown.Menu align="end">
                <div className="notification-menu">
                  <p className="fw-7 fs-09">Уведомления</p>
                  <p className="text-muted fs-09">
                    У вас нет новых уведомлений
                  </p>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="d-flex align-items-center ms-3 ms-sm-4">
              <Dropdown.Toggle
                as={React.forwardRef(({ children, onClick }, ref) => (
                  <Nav.Link
                    ref={ref}
                    className="py-0"
                    onClick={(e) => {
                      e.preventDefault();
                      onClick(e);
                    }}
                  >
                    <img
                      src="/images/avatar-full.png"
                      height={50}
                      className="user-photo"
                    />
                  </Nav.Link>
                ))}
              />
              <Dropdown.Menu align="end">
                <div className="account-menu">
                  <p className="fw-7 fs-09">
                    {state.auth.user.firstName} {state.auth.user.lastName}
                  </p>
                  <p className="text-muted fs-09">ID {state.auth.user.id}</p>
                </div>
                <Dropdown.Divider className="dashed" />
                <Dropdown.Item as={Link} to="/account">
                  Профиль
                </Dropdown.Item>
                {/* <Dropdown.Item as={Link} to={"/member/" + state.auth.user.id}>
                  Мои задачи
                </Dropdown.Item> */}
                {/* <Dropdown.Item as={Link} to="/payment">
                  Оплата
                </Dropdown.Item> */}
                <Dropdown.Divider className="dashed" />
                <Dropdown.Item onClick={() => setShowLogout(!showLogout)}>
                  Выход
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <button
              className="btn-icon ms-3 ms-sm-4 d-lg-none"
              onClick={() => setShowBurgerMenu(true)}
            >
              <IoMenu />
            </button>
          </nav>
        </Container>
      </header>
      {/* 
      <MobileNav /> */}

      <Offcanvas
        show={isShowBurgerMenu}
        onHide={closeBurgerMenu}
        placement="end"
      >
        <Offcanvas.Body>
          <Menu onClick={() => setShowBurgerMenu(false)} />
          <button
            type="button"
            className="close"
            onClick={() => setShowBurgerMenu(false)}
          >
            <IoCloseCircle />
          </button>
        </Offcanvas.Body>
      </Offcanvas>

      <CustomModal
        show={showLogout}
        setShow={setShowLogout}
        title="Подтвердите действие"
        footer={
          <>
            <Button
              type="button"
              className="btn-default"
              onClick={() => setShowLogout(!showLogout)}
            >
              Отмена
            </Button>
            <Button
              type="button"
              className="btn-primary"
              onClick={() => {
                dispatch(logout());
                navigate("/login");
              }}
            >
              Выйти
            </Button>
          </>
        }
      >
        Вы точно хотите выйти?
      </CustomModal>
    </>
  );
};

export default Header;
